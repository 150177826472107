import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/max/Documents/node-projects/maxs-portfolio/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2><a parentName="h2" {...{
        "href": "https://kill-weezer.web.app/"
      }}>{`Kill Weezer`}</a></h2>
    <p>{`barely works, based on the infamous scratch game  `}</p>
    <p>{`i wish no ill will upon the band members  `}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/kill-weezer"
      }}>{`Github`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      